* {
    
  }
.container-fluid {
    margin: 0;
    padding: 0;
}
body {
    background-color: #f1f0f8;
    margin: 0;
    padding: 0;
}

.leaflet-container {
    position: absolute;
    height: 100%;
    width: 100vw;
}


/* Home Page */

.carousel-back {
    height: 50vh;
    background-color: rgba(0, 0, 0, 0.822);
    width: 100vw;
}

.home-section {
    height: 90vh;
    position: relative;
}

.home-section .home-heading {
    position: absolute;
    top: 35%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    font-size: 60px;
}

.home-section .home-button {
    position: absolute;
    top: 47%;
    left: 50%;
    height: 50px;
    font-size: 15px;
    margin-right: -50%;
    transform: translate(-50%, -50%)
}

.home-section .home-text {
    position: absolute;
    top: 65%;
    left: 50%;
    text-align: center;
    transform: translate(-50%, -50%)
}

.footer {
    color: lightslategray;
    position: fixed;
    bottom: 5px;
    font-size: 1vw;
    margin: 0;
}

@media (max-width: 800px) { 
    .home-section .home-heading {
        font-size: 40px;
    }
    
}

/* Mask Page */
.mask-section {
    margin-left: 8vw;
    margin-right: 8vw;
}

.mask-title {
    font-size: 50px;
    text-align: center;
    margin-top: 10vh;
    margin-bottom: 5vh;
}

.mask-card {
    margin-bottom: 5vh;

}

.mask-materials {
    width: 400px;
    margin-bottom: 5vh;
}

.mask-text {
    margin-bottom: 5vh;
}

.mask-img {
    width: 40%;
}
